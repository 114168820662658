export type TabInfo = {
  key: TabKeys;
  icon: string;
  order: number;
  routeName: string;
};

export const tabs = {
  MARKETS: {
    key: 'markets',
    icon: 'markets',
    order: 1,
    routeName: 'mobile-markets',
  },
  INSTANT: {
    key: 'portfolio',
    icon: 'chart',
    order: 2,
    routeName: 'portfolio',
  },
  ACCOUNTS: {
    key: 'accounts',
    icon: 'row-vertical',
    order: 2,
    routeName: 'mobile-accounts',
  },
  PROFILE: {
    key: 'profile',
    icon: 'user-square',
    order: 3,
    routeName: 'mobile-profile',
  },
} as const;

export type Tabs = keyof typeof tabs;
export type TabValues = (typeof tabs)[Tabs];
export type TabKeys = TabValues['key'];

export default tabs;
