import { identifiersService } from '@exchange/libs/utils/client-identifiers/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';
import { wssLogPrefix, ChannelTemplate, type ChannelListenerTemplate } from '@exchange/libs/utils/wss/src';
import { wsFrontendErrors } from '@exchange/libs/utils/wss/src/lib/websocket-model';
import {
  WSChannelNames,
  WSIncomingEventTypes,
  WSIncomingEventTypesSpecial,
  WSOutgoingEventTypesSpecial,
  wsSportChannelNames,
  wsSportSAuthorizationErrors,
  type WSErrorMessage,
  type WSOutgoingAuthenticateMessagePayload,
  type WSOutgoingSubscribeMessagePayload,
} from '@exchange/libs/utils/wss/src/lib/websocket-spot-model';

import type { AccountHistoryMessage } from './wss-account-messages';

export type AccountChannelGetTokenListener = () => Promise<string | undefined>;

export interface AccountChannelListener extends ChannelListenerTemplate<AccountHistoryMessage> {
  getAccessToken: AccountChannelGetTokenListener;
}

export class AccountHistoryChannel extends ChannelTemplate<AccountHistoryMessage, AccountChannelListener> {
  protected getSubscribeMessage() {
    const subscribeMessage: WSOutgoingSubscribeMessagePayload = {
      type: WSOutgoingEventTypesSpecial.SUBSCRIBE,
      channels: [
        {
          name: wsSportChannelNames.ACCOUNT_HISTORY,
        },
      ],
    };

    return subscribeMessage;
  }

  protected get channelName(): WSChannelNames {
    return wsSportChannelNames.ACCOUNT_HISTORY;
  }

  protected override async openChannel(listener: AccountChannelListener) {
    await this.authenticate(listener);
    await super.openChannel(listener);
  }

  private async authenticate(listener: AccountChannelListener) {
    const accessToken = await listener.getAccessToken();

    if (!accessToken) {
      logger.warn(`${wssLogPrefix}AccountHistory: attempt to authenticate without api_token`);
      throw new Error(wsFrontendErrors.MISSING_API_TOKEN);
    }

    const authenticateMessage: WSOutgoingAuthenticateMessagePayload = {
      type: WSOutgoingEventTypesSpecial.AUTHENTICATE,
      api_token: accessToken,
      request_id: identifiersService.sessionId,
    };

    try {
      // We will need to move the authenticate message if we ever get more than one authenticated channel
      await this.webSocketManager.request(
        {
          message: authenticateMessage,
          successMatcher: (m) => m.type === WSIncomingEventTypesSpecial.AUTHENTICATED,
          failureMatcher: (m) => {
            const error = (m as WSErrorMessage)?.error;

            return m.type === WSIncomingEventTypes.ERROR && typeof error === 'string' && (Object.values(wsSportSAuthorizationErrors) as Array<string>).includes(error);
          },
        },
        20000,
      );
    } catch (e) {
      logger.warn(`${wssLogPrefix}Failed to authenticate:`, e);

      this.handleRequestFailure(e, () => this.authenticate(listener));
    }
  }
}

export default new AccountHistoryChannel();
