import getBalance from '@exchange/helpers/balance';
import BigNumber from '@exchange/helpers/bignumber';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';

import { getTotalInValue, hasLowBalance } from './helpers';

/* eslint-disable camelcase */
export interface BEBalanceModel {
  available: string;
  currency_code: string;
  locked: string;
  sequence: number;
}
export interface BEWSBalanceModel {
  available: string;
  change: number;
  currency_code: string;
  locked: string;
  sequence: string;
  time: string;
}
export interface UIBalanceModel {
  available: BigNumber;
  currency_code: string;
  locked: BigNumber;
  sequence: number;
}

export interface BalanceUpdate {
  currency_code: string;
  amount_available: string;
  amount_locked: string;
}
/* eslint-enable camelcase */

export const getDefaultBalanceState = (value: string | BEBalanceModel | BEWSBalanceModel | UIBalanceModel): BEBalanceModel | BEWSBalanceModel | UIBalanceModel => {
  if (typeof value === 'string') {
    return {
      available: '0',
      currency_code: value,
      locked: '0',
      sequence: -1,
    };
  }

  return value;
};

export default class BalanceModel {
  public available: BigNumber;

  public locked: BigNumber;

  public total: BigNumber;

  public totalCryptoValue: BigNumber;

  public totalFiatValue: BigNumber;

  public lockedFiatValue: BigNumber;

  public availableFiatValue: BigNumber;

  public sequence: number;

  public portfolioSize = 0;

  public currencyCode: string;

  public hasLowBalance = false;

  constructor(data: string | BEBalanceModel | BEWSBalanceModel | UIBalanceModel) {
    const fields = getDefaultBalanceState(data);

    this.available = new BigNumber(fields.available);
    this.currencyCode = fields.currency_code;
    this.locked = new BigNumber(fields.locked);
    this.sequence = Number(fields.sequence);
    this.total = this.getTotal();
    this.totalCryptoValue = new BigNumber(NaN);
    this.totalFiatValue = new BigNumber(NaN);
    this.lockedFiatValue = new BigNumber(NaN);
    this.availableFiatValue = new BigNumber(NaN);
  }

  public getTotal = () => this.available.plus(this.locked);

  public getTotalIn = (currency: { defaultCryptoValue: number; defaultFiatValue: number; precision: number }) => {
    const cryptoPrecision = CONSTANTS.PRECISION.DEFAULT_CRYPTO;
    const fiatPrecision = CONSTANTS.PRECISION.DEFAULT_FIAT;

    const total = this.getTotal();
    const totalFiat = getTotalInValue(total, currency.defaultFiatValue);
    const totalCrypto = getTotalInValue(total, currency.defaultCryptoValue);

    return {
      total,
      totalCryptoValue: new BigNumber(getBalance({ v: totalCrypto, precision: cryptoPrecision })),
      totalFiatValue: new BigNumber(getBalance({ v: totalFiat, precision: fiatPrecision })),
    };
  };

  public getLockedIn = (currency: { defaultFiatValue: number }) => ({
    lockedFiatValue: getTotalInValue(this.locked, currency.defaultFiatValue),
  });

  public getPortfolioSize = (totalCryptoValue: BigNumber, balanceCryptoValue: BigNumber) => {
    let portfolioSize: number;

    if (!balanceCryptoValue.isNaN()) {
      portfolioSize = balanceCryptoValue.div(totalCryptoValue).times(100).toNumber();
    } else {
      portfolioSize = NaN;
    }

    return portfolioSize;
  };

  public updateHasLowBalance = (value: boolean) => {
    this.hasLowBalance = value;
  };

  public updateTotalValues = (value: { total: BigNumber; totalCryptoValue: BigNumber; totalFiatValue: BigNumber; lockedFiatValue: BigNumber; availableFiatValue: BigNumber }) => {
    this.total = value.total;
    this.totalCryptoValue = value.totalCryptoValue;
    this.totalFiatValue = value.totalFiatValue;
    this.lockedFiatValue = value.lockedFiatValue;
    this.availableFiatValue = value.availableFiatValue;
  };

  public calculateAndUpdateTotalValues = (currency?: { defaultCryptoValue: number; defaultFiatValue: number; precision: number }) => {
    const { defaultCryptoValue, defaultFiatValue, precision } = currency || { defaultCryptoValue: 0, defaultFiatValue: 0, precision: CONSTANTS.PRECISION.DEFAULT_UNKNOWN_CURRENCY };
    const { total, totalCryptoValue, totalFiatValue } = this.getTotalIn({ defaultCryptoValue, defaultFiatValue, precision });
    const { lockedFiatValue } = this.getLockedIn({ defaultFiatValue });
    const { availableFiatValue } = this.getAvailableIn({ defaultFiatValue });

    this.updateHasLowBalance(hasLowBalance(totalFiatValue));
    this.updateTotalValues({
      total,
      totalCryptoValue,
      totalFiatValue,
      lockedFiatValue,
      availableFiatValue,
    });
  };

  public updatePortfolioSize = (v: number) => {
    this.portfolioSize = v;
  };

  public getAvailableIn = (currency: { defaultFiatValue: number }) => ({
    availableFiatValue: getTotalInValue(this.available, currency.defaultFiatValue),
  });
}
